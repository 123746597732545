import { cipo } from 'cipo';

cipo.factory("User", function ($q, Model, Form, URI, userService, Dictionaries, Message, Permissions) {
    var User = Model.extend(function (obj) {
        var self = this;

        self.tabWarning = false;
        self.hightlight = false;
        self.loadingDetails = true;
        self.assignmentsList = [];
        self.backupAssignmentsList = [];
        self.lookupRoles = {};
        self.lookupContracts = {};
        self.isUserRateEnabled = false;
        self.isUserAllocationEnabled = false;
        
        self.sortableOptions = {
            update: function () { self.isMainRolesOrderChanged = true; },
            handle: '> span>.listHandle',
            'ui-floating': true
        };
        
        if ((obj || {}).id) {
            self.loadingAssignments = true;
        }

        self.inviteId = (obj || {}).inviteId && (obj.inviteId != -1) ? obj.inviteId : null;

        self.process = {
            loaded: false,
            error: null
        };
        
        self.init();

        if ((obj || {}).id) {
            for (var key in obj)
                if (obj.hasOwnProperty(key))
                    self.properties[key] = obj[key];
            if (obj.status) self.status = obj.status;
        }

        self.form = new Form(self.properties);
        self.form.initializing = true;

        $q.all([
            Dictionaries.Organizations(), 
            Dictionaries.AllRoles(), 
            Dictionaries.UserContracts({ userId: self.properties.id }),
            Dictionaries.TenantSettings()
        ]).then(function (r)
        {
            self.organizationDict = r[0];
            self.rolesDict = (r[1] || []).filter(role => !role.isDisabled);
            for (var i = 0; i < self.rolesDict.length; i++) {
                self.lookupRoles[self.rolesDict[i].key] = self.rolesDict[i];
            }
            self.contractsDict = r[2];
            for (var i = 0; i < self.contractsDict.length; i++) {
                self.lookupContracts[self.contractsDict[i].key] = self.contractsDict[i];
            }

            self.isUserRateEnabled = (r[3] || []).some(s => s.name == 'user_rate' && s.value == '1');
            self.isUserAllocationEnabled = (r[3] || []).some(s => s.name == 'user_allocation' && s.value == '1');

            self.createForm();
        });

        Object.defineProperty(self, 'isDirtyAssignments', {
            get: function () {
                var isDirty = false;
                for (var i = 0; i < self.assignmentsList.length; i++) {
                    if (self.assignmentsList[i].isDelete || self.assignmentsList[i].isChanged) {
                        isDirty = true;
                        break;
                    }

                }
                return isDirty;
            }
        });

        Object.defineProperty(self, 'totalAllocationHoursPerMonth', {
            get: function () {
                return self.assignmentsList.map(a => (a.allocationHoursPerMonth * 1) || 0).reduce((a, c) => (a + c), 0);
            }
        })

        Object.defineProperty(self, 'maxCapacityHoursPerMonthExceededValue', {
            get: function () {
                return self.totalAllocationHoursPerMonth - self.properties.maxCapacityHoursPerMonth;
            }
        })

        Object.defineProperty(self, 'isMaxCapacityHoursPerMonthExceeded', {
            get: function () {
                return self.maxCapacityHoursPerMonthExceededValue > 0;
            }
        })
    });

    User.prototype.createForm = function ()
    {
        var self = this;

        var form = {
            email: { label: 'Email', type: 'text', validation: { required: true, email: true } },
            org: { label: 'Select Organization', type: 'select', options: self.organizationDict, validation: { required: true } },
            roleIds: { label: 'Select Roles', type: 'select', multiple: true, options: self.rolesDict, validation: { required: true } },
            contractIds: { label: 'Select Contracts', type: 'select', multiple: true, options: self.contractsDict },
        };
        if (self.isUserRateEnabled) {
            form['rateDefault'] = { label: 'Rate (default)', type: 'number', formattingsLookup: { 2: 2 }, validation: { isNumber: true }  };
        }
        if (self.isUserAllocationEnabled) {
            form['maxCapacityHoursPerMonth'] = { label: 'Maximum Capacity (hours per month)', type: 'number', validation: { isNumber: true }  };
        }

        var grid;

        if (self.inviteId) {
            self.get_Data();
            form.logo = { label: '', type: 'editor', editMode: false };
            form.firstName = { label: 'First Name', type: 'text', editMode: false };
            form.lastName = { label: 'Last Name', type: 'text', editMode: false };

            grid = [
                { logo: 20, firstName: 40, lastName: 40 },
                { email: 50, org: 50 },
                !self.isUserAllocationEnabled && self.isUserRateEnabled ? { rateDefault: 100 } : null,
                self.isUserAllocationEnabled && !self.isUserRateEnabled ? { maxCapacityHoursPerMonth: 100 } : null,
                self.isUserAllocationEnabled && self.isUserRateEnabled ? { rateDefault: 50, maxCapacityHoursPerMonth: 50 } : null,
                { roleIds: 100 }
            ].filter(x => x != null);
        }
        else {
            grid = [
                { email: 50, org: 50 },
                !self.isUserAllocationEnabled && self.isUserRateEnabled ? { rateDefault: 100 } : null,
                self.isUserAllocationEnabled && !self.isUserRateEnabled ? { maxCapacityHoursPerMonth: 100 } : null,
                self.isUserAllocationEnabled && self.isUserRateEnabled ? { rateDefault: 50, maxCapacityHoursPerMonth: 50 } : null,
                { roleIds: 50, contractIds: 50 }
            ].filter(x => x != null);
        }

        self.form.set_Description(form);
        self.form.setTemplate('grid', grid);

        self.form.set_Data(self.properties);
        self.form.fieldsList.roleIds.onClose = function (field) {
            if (!angular.equals(field._value, self.form.originalData.roleIds)) {
                self.properties.roles = [];
                for (var j = 0; j < field._value.length; j++) {
                    if (self.lookupRoles[field._value[j]]) {
                        var role = angular.copy(self.lookupRoles[field._value[j]]);
                        role.order = j + 1;
                        self.properties.roles.push(role);
                    }
                }
            }
        };

        if (!self.inviteId) {
            self.form.fieldsList.contractIds.onClose = function (field) {
                if (!angular.equals(field._value, self.form.originalData.contractIds)) {
                    self.properties.contracts = [];
                    for (var j = 0; j < field._value.length; j++) {
                        if (self.lookupContracts[field._value[j]]) {
                            var contract = angular.copy(self.lookupContracts[field._value[j]]);
                            contract.order = j + 1;
                            self.properties.contracts.push(contract);
                        }
                    }
                }
            };
        }
        
        self.form.store_Data();
        self.form.initializing = false;
    };

    User.prototype.init = function (isAdd)
    {
        var self = this;
        if (self.inviteId)
            self.properties = {
                org: null,
                email: "",
                firstName: "",
                name: "",
                id: null,
                inviteId: null,
                organization: "",
                lastName: "",
                roleIds: [],
                status: "",
                logo: "",
                permissions: [],
                rateDefault: null,
                maxCapacityHoursPerMonth: null,
            };
        else
        {
            // info user create
            self.properties = {
                email: "",
                roleIds: [],
                contractIds: [],
                org: null,
                isExternal: false,
                permissions: [1, 2],
                rateDefault: null,
                maxCapacityHoursPerMonth: null,
            };
        }

            if (isAdd) {
                
                self.form.set_Data(self.properties);
                self.form.fieldsList.roleIds.onClose = function (field) {
                    if (!angular.equals(field._value, self.form.originalData.roleIds)) {
                        self.properties.roles = [];
                        for (var j = 0; j < field._value.length; j++) {

                        if (self.lookupRoles[field._value[j]]) {
                            var role = angular.copy(self.lookupRoles[field._value[j]]);
                            role.order = j + 1;
                            self.properties.roles.push(role);
                        }
                    }
                }


            };
            self.form.store_Data();

        }
        if (!this.inviteId || isAdd) {
            this.inviteId = null;
            self.operations = {};
            for (var j = 0; j < self.properties.permissions.length; j++) {
                Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
            }
        }
    };

    User.prototype.get_Data = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        self.rolesIds = [];
        self.loadingDetails = true;
        self[URI.USER.DETAIL.method](URI.USER.DETAIL, { inviteId: self.inviteId }).then(function (result) {
            if (result) {
                self.operations = {};
                for (var key in result) {
                    if (result.hasOwnProperty(key)) {
                        self.properties[key] = result[key];
                    }
                    if (self.properties.logo && !self.properties.logo.startsWith('<img src')) {
                        self.properties.logo = '<img src="data:image/png;base64,' + self.properties.logo + '"/>';
                    }
                }
                if (self.properties.roles && self.properties.roles.length) {
                    for (var i = 0; i < self.properties.roles.length; i++) {
                        self.rolesIds.push(self.properties.roles[i].id);
                    }
                }

                // self.properties.permissions = [1, 2, 3];
                if ((self.properties.permissions || []).length) {
                    for (var j = 0; j < self.properties.permissions.length; j++) {
                        Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
                    }
                }
                    self.form.set_Data(self.properties);
                    
                    self.form.fieldsList.roleIds.onClose = function (field) {
                        if (!angular.equals(field._value, self.form.originalData.roleIds)) {
                            self.properties.roles = [];
                            for (var j = 0; j < field._value.length; j++) {

                            if (self.lookupRoles[field._value[j]]) {
                                var role = angular.copy(self.lookupRoles[field._value[j]]);
                                role.order = j + 1;
                                self.properties.roles.push(role);
                            }
                        }
                    }
                };
                self.form.store_Data();
                self.status = result.status;

                self.get_assignments();
            }
        }).catch(function (reject) {
            self.process.error = reject;
            console.error(reject);
        }).finally(function () {
            self.form.loading = false;
            self.process.loaded = true;
            self.loadingDetails = false;
            p.resolve();
        });

        return p.promise;
    }

    User.prototype.get_assignments = function () {
        var self = this;
        var p = $q.defer();
        
        
        Dictionaries.UserContracts({ userId: self.properties.id })
            .then(function (r) {
                
                self.assignmentsDict = r;
                
                self.isAssignmentsDictLoaded = true;
                self.get_assignmentsList();
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            });

        return p.promise;
    }

    User.prototype.revertAssignmentsChanges = function () {
        var self = this;
        self.assignmentsList = angular.copy(self.backupAssignmentsList);
        
    }

    User.prototype.updateRoleLine = function (item, isEdit) {
        var self = this;
        item.rolesLine = "";
        var rolesLine = [];
        if ((item.roleIds || []).length) {

            if (isEdit) {
                item.roles = [];
                for (var j = 0; j < item.roleIds.length; j++) {

                    if (self.lookupRoles[item.roleIds[j]]) {
                        var role = angular.copy(self.lookupRoles[item.roleIds[j]]);
                        role.order = j + 1;
                        rolesLine.push(role.value);
                        item.roles.push(role);
                    }
                }
            } else {
                item.backupRoleIds = angular.copy(item.roleIds);
                for (var j = 0; j < item.roles.length; j++) {
                    rolesLine.push(item.roles[j].value);
                }
            }

        }

        if (rolesLine.length) {
            item.rolesLine = rolesLine.join(', ');
        }

        return item;
    };

    User.prototype.updateAssignment = function (item) {
        item = this.updateRoleLine(item, true);
        if (!angular.equals(item.roleIds, item.backupRoleIds)) {
            item.isChanged = true;
        }
    }
    
    User.prototype.openAssignmentDialog = function () {
        var self = this;
        self.isShowingRoles = !self.isShowingRoles;
        self.isBusy = !self.isBusy;
        self.assigmentsDictBkp = angular.copy(self.assignmentsDict);
    }

    User.prototype.cancelAssignmentDialog = function () {
        var self = this;
        self.isShowingRoles = !self.isShowingRoles;
        self.isBusy = !self.isBusy;
        self.assignmentsDict = angular.copy(self.assigmentsDictBkp);
    }

    User.prototype.get_assignmentsList = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.ROLES.GET_CONTRACTS;
        self.isAssignmentsLoaded = false;
        

        self[urlData.method](urlData, { url: { userId: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                
                for (var i = 0; i < r.data.length; i++) {
                    r.data[i] = self.updateRoleLine(r.data[i]);
                    r.data[i].isDelete = false;
                    r.data[i].isChanged = false;
                }
                self.searchContract = "";
                self.assignmentsList = r.data;

                self.backupAssignmentsList = angular.copy(self.assignmentsList);

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                
                self.isAssignmentsLoaded = true;
            });

        return p.promise;
    }

    User.prototype.sync_assignments = function () {
        var self = this;
        var p = $q.defer();
        self.isSavingAssignments = true;
        var urlData = URI.ROLES.ASSIGN_CONTRACTS_TO_USER;
        var toSend = [];
        for (var i = 0; i < self.assignmentsDict.length; i++) {
            if (self.assignmentsDict[i].isUsed) toSend.push(self.assignmentsDict[i].key);
        }
        self[urlData.method](urlData, { url: {}, body: { id: self.properties.id, contractIds: toSend }, urltype: 'obj' })
            .then(function (r) {
                userService.refreshContext();
                self.isShowingRoles = false;
                self.get_assignmentsList();
                
                Message.info('Assignments saved successfully.');
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isSavingAssignments = false;
                self.isBusy = false;
            });

        return p.promise;
    }

    User.prototype.save_assignments = function (isAdd) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlData = URI.ROLES.SYNC_ROLES_TO_USER;
        var toSend = { id: self.properties.id, contracts: [] };
        for (var i = 0; i < self.assignmentsList.length; i++) {
            if (self.assignmentsList[i].roleIds.length && !self.assignmentsList[i].isDelete) {
                toSend.contracts.push({ 
                    contractId: self.assignmentsList[i].contractId, 
                    roles: self.assignmentsList[i].roles,
                    allocationHoursPerMonth: self.assignmentsList[i].allocationHoursPerMonth,
                    userRate: self.assignmentsList[i].userRate,
                });
            }
        }
        self[urlData.method](urlData, { url: {  }, body: toSend, urltype: 'obj' })
            .then(function (r) {
                userService.refreshContext();
                Message.info('Assignments saved successfully.');
                p.resolve();
                if (!isAdd) {
                    self.isAssignmentsLoaded = false;
                    self.backupAssignmentsList = angular.copy(self.assignmentsList);
                    
                    self.get_assignments();
                    
                } else {
                    self.inviteId = null;
                    self.init(true);
                    self.createForm();
                }
                
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
            });

        return p.promise;
    }
    
    User.prototype.save = function (isAdd) {
        var self = this;
        var p = $q.defer();

        self.form.loading = true;

        self.form.validate();
        if (self.form.isValid) {
            if (self.properties.roles && self.properties.roles.length) {
                for (var i = 0; i < self.properties.roles.length; i++) {
                    self.properties.roles[i].order = i + 1;
                }
            }
            if (self.inviteId) {
                var toSend = angular.copy(self.properties);
                delete toSend.logo;
                self[URI.USER.SAVE.method](URI.USER.SAVE, toSend)
                    .then(function (r) {
                        self.isMainRolesOrderChanged = false; 
                        Message.info('User updated successfully');
                        if (isAdd) {
                            self.inviteId = null;
                            self.init(isAdd);
                            self.createForm();
                        }
                        
                        self.form.set_Data(self.properties);
                        self.form.fieldsList.roleIds.onClose = function (field) {
                            if (!angular.equals(field._value, self.form.originalData.roleIds)) {
                                self.properties.roles = [];
                                for (var j = 0; j < field._value.length; j++) {

                                if (self.lookupRoles[field._value[j]]) {
                                    var role = angular.copy(self.lookupRoles[field._value[j]]);
                                    role.order = j + 1;
                                    self.properties.roles.push(role);
                                }
                            }
                        }
                    };

                    self.form.store_Data();
                    p.resolve();
                })
                .catch(function (e) {
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    self.form.loading = false;
                });
            }
            else {

                var params = {
                    url: { roleId: self.selectedRoleId }, urltype: 'obj',
                        body: self.properties
                    };

                    self[URI.USER.ADD.method](URI.USER.ADD, params)
                    .then(function (r) {
                        Message.info('Invitation sent successfully');
                        if (isAdd) {
                            self.init();
                            // self.createForm();
                        }
                        self.isMainRolesOrderChanged = false; 
                        self.form.set_Data(self.properties);
                        self.form.fieldsList.roleIds.onClose = function (field) {
                            if (!angular.equals(field._value, self.form.originalData.roleIds)) {
                                self.properties.roles = [];
                                for (var j = 0; j < field._value.length; j++) {

                                    if (self.lookupRoles[field._value[j]]) {
                                        var role = angular.copy(self.lookupRoles[field._value[j]]);
                                        role.order = j + 1;
                                        self.properties.roles.push(role);
                                    }
                                }
                            }
                        };
                        self.form.fieldsList.contractIds.onClose = function (field) {
                            if (!angular.equals(field._value, self.form.originalData.contractIds)) {
                                self.properties.contracts = [];
                                for (var j = 0; j < field._value.length; j++) {
                                    if (self.lookupContracts[field._value[j]]) {
                                        var contract = angular.copy(self.lookupContracts[field._value[j]]);
                                        contract.order = j + 1;
                                        self.properties.contractIds.push(contract);
                                    }
                                }
                            }
                        };
                        self.form.store_Data();
                        p.resolve();
                    })
                    .catch(function (e) {
                        self.form.catch(e);
                        p.reject(e);
                    })
                    .finally(function () {
                        self.form.loading = false;
                    });
            }
        } else {
            self.form.loading = false;
            p.reject();
        }
        
        return p.promise;
    }
            
    User.prototype.disable = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true; 
        self[URI.USER.DISABLE.method](URI.USER.DISABLE, { list: [self.inviteId] })
                .then(function (r) {
                    p.resolve(r);
                })
                .catch(function (e) {
                    p.reject(e);
            })
            .finally(function () { self.isBusy = false; });

        return p.promise;
    }

    User.prototype.enable = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true; 
        self[URI.USER.ENABLE.method](URI.USER.ENABLE, { list: [self.inviteId] })
                .then(function (r) {
                    p.resolve(r);
                })
                .catch(function (e) {
                    p.reject(e);
            })
            .finally(function () { self.isBusy = false; });
        return p.promise;
    }

    return User;
});
