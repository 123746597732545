import { KeyValueModel } from '../../common';
import { FieldTypeEnum } from '../fields/enums';

export enum FilterLogic {
  AND = 0,
  OR = 1,
}

export enum FilterOperator {
  Equal = 0,
  NotEqual = 1,
  Contains = 20,
  DoesNotContain = 21,
  StartsWith = 22,
  EndsWith = 23,
  ContainsWithIgnoreCase = 24,
  LessThan = 30,
  LessThanOrEqual = 31,
  MoreThan = 32,
  MoreThanOrEqual = 33,

  Between = 40,
  On = 41,
  Before = 42,
  OnOrBefore = 43,
  After = 44,
  OnOrAfter = 45,
  NotOn = 46,
}

export const FilterOperators = {
  Equal: 0,
  NotEqual: 1,
  Contains: 20,
  DoesNotContain: 21,
  StartsWith: 22,
  EndsWith: 23,
  ContainsWithIgnoreCase: 24,
  LessThan: 30,
  LessThanOrEqual: 31,
  MoreThan: 32,
  MoreThanOrEqual: 33,
  Between: 40,
  On: 41,
  Before: 42,
  OnOrBefore: 43,
  After: 44,
  OnOrAfter: 45,
  NotOn: 46,
  Last30Days: 40,
  Last2Weeks: 40,
} as const;

export type FilterOperatorKey = keyof typeof FilterOperators;
export type FilterOperatorValue = (typeof FilterOperators)[keyof typeof FilterOperators];

export const FilterNumberOperators: FilterOperatorKey[] = [
  'Between',
  'Equal',
  'LessThan',
  'LessThanOrEqual',
  'MoreThan',
  'MoreThanOrEqual',
  'NotEqual',
];
export const FilterTextOperators: FilterOperatorKey[] = [
  'Contains',
  'ContainsWithIgnoreCase',
  'DoesNotContain',
  'EndsWith',
  'StartsWith',
];

export const FilterDateOperators: FilterOperatorKey[] = [
  'After',
  'Before',
  'Between',
  'NotOn',
  'On',
  'OnOrAfter',
  'OnOrBefore',
  'Last30Days',
  'Last2Weeks',
];

export interface FilterModel {
  field?: string;
  operator?: FilterOperator;
  value?: any;
  logic?: FilterLogic;
  filters?: FilterModel[];
  //
  fieldTypeId?: FieldTypeEnum;
  options?: string;
  dataSource?: KeyValueModel<string, string>;
  containsBadData?: boolean;
}
