<div class="p-x-xl cipo-bg--white">
    <app-data-list-command (applyfilters)="applyFilters($event)" [filters]="filters" sidenavclass="p-y-3xl">
        <div fxLayout fxLayoutAlign="space-between center">
            <div fxLayoutGap="16px">
                @if (canCreate()) {
                    <button mat-raised-button color="primary" (click)="addEditHoliday()">
                        <mat-icon class="material-symbols-outlined">calendar_add_on</mat-icon>
                        {{ "general.table.addNew" | translate }}
                    </button>
                }

                <button mat-raised-button (click)="filterByYear(currentYear())">
                    <mat-icon class="material-symbols-outlined">calendar_month</mat-icon>
                    {{ currentYear() }}
                </button>

                <button mat-raised-button (click)="filterByYear(currentYear() + 1)">
                    <mat-icon class="material-symbols-outlined">calendar_month</mat-icon>
                    {{ currentYear() + 1 }}
                </button>
            </div>
            @if (canDelete()) {
                <button mat-icon-button (click)="deleteHolidays()">
                    <mat-icon>delete</mat-icon>
                </button>
            }
        </div>
        <cipo-paged-data-list
            class="data-list"
            [tableColumns]="tableColumns"
            [tableData]="tableData"
            [loading]="isLoading()"
            [options]="tableOptions"
            (rowClicked)="addEditHoliday($any($event))"
            (selectionChanged)="selectedRows.set($any($event))"
            (actionSelected)="handleAction($any($event))" />
    </app-data-list-command>
</div>
