import { Component, inject, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { State, Store } from '@ngrx/store';
import { filter } from 'rxjs';

import { HolidayActionsEnum, HolidayModel, HolidayRow, HolidaysService, HolidayTableOptions } from './common';
import { SharedComponentsModule } from '../../../shared/modules/shared-components.module';
import { AddEditHolidayComponent } from './add-edit-holiday/add-edit-holiday.component';
import {
  FiltersToApply,
  ActionEventType,
  DisplayFormats,
  TableColumn,
} from '../../../shared/components/data-list/common';
import { PAGE_SIZE_OPTIONS } from '../../../shared/consts';
import { BaseRestrictions } from '../../../models/module/fields/enums';
import { AppState } from '../../../../app.state';
import { Permission } from '../../../models/permission';
import { ConfirmationDialog } from '../../../shared/dialogs/confirmation-dialog.component';
import { PagedDataListComponent } from '../../../shared/components/data-list/paged-data-list/paged-data-list.component';
import { FilterOperator } from '../../../models/module/grid';

@Component({
  selector: 'app-holidays',
  standalone: true,
  imports: [PagedDataListComponent, SharedComponentsModule],
  templateUrl: './holidays.component.html',
})
export class HolidaysComponent {
  dialog = inject(MatDialog);
  holidayService = inject(HolidaysService);
  translate = inject(TranslateService);
  store = inject<Store<AppState>>(Store);
  state = inject<State<AppState>>(State);
  activeItemPermissions = (this.state.value as AppState).core.menu.activeItem?.operations.map(
    operation => operation.id,
  );

  canCreate = signal(this.activeItemPermissions?.includes(Permission.Create));
  canDelete = signal(this.activeItemPermissions?.includes(Permission.Delete));
  canView = signal(this.activeItemPermissions?.includes(Permission.Read));
  canUpdate = signal(this.activeItemPermissions?.includes(Permission.Update));
  currentYear = signal(new Date().getFullYear());

  selectedRows = signal<HolidayRow[]>([]);
  filters = this.holidayService.getFilters();
  holidayList: HolidayModel[] = [];

  tableOptions: HolidayTableOptions = {
    clickableRows: true,
    selectable: true,
    pagination: {
      pageSizeOptions: PAGE_SIZE_OPTIONS.default,
      pageSize: PAGE_SIZE_OPTIONS.default[1],
      pageIndex: 0,
    },
    actions: () => {
      return [
        {
          id: HolidayActionsEnum.VIEW,
          displayName: this.translate.instant('general.table.view'),
          rightIcon: 'edit',
          condition: () => this.canView() && !this.canUpdate(),
        },
        {
          id: HolidayActionsEnum.EDIT,
          displayName: this.translate.instant('general.table.edit'),
          rightIcon: 'edit',
          condition: () => this.canUpdate(),
        },
        {
          id: HolidayActionsEnum.DELETE,
          displayName: this.translate.instant('general.table.delete'),
          rightIcon: 'delete',
          condition: () => this.canDelete(),
        },
      ];
    },
  };
  tableColumns: TableColumn[] = [
    {
      id: 1,
      name: 'name',
      displayName: this.translate.instant('holidays.name'),
      width: 3,
      sortable: true,
    },
    {
      id: 2,
      name: 'date',
      displayName: this.translate.instant('holidays.date'),
      width: 2,
      displayFormatId: DisplayFormats.Date,
      restrictions: BaseRestrictions.Date,
      sortable: true,
    },
    {
      id: 3,
      name: 'isWorkDay',
      displayName: this.translate.instant('holidays.isWorkDay'),
      displayFormatId: DisplayFormats.YesNo,
      sortable: true,
    },
  ];
  tableData: HolidayRow[];
  isLoading = signal(false);

  constructor() {
    this.loadHolidays();
  }

  loadHolidays() {
    this.isLoading.set(true);
    this.holidayService.getAllHolidays().subscribe(data => {
      this.holidayList = data;
      this.tableData = data.map(holiday => ({ ...holiday }));
      this.isLoading.set(false);
    });
  }

  addEditHoliday(holiday?: HolidayRow) {
    this.dialog.open(AddEditHolidayComponent, {
      panelClass: ['cipo-dialog', 'classic'],
      width: '600px',
      data: holiday,
    });
  }

  applyFilters(filters: FiltersToApply) {
    console.log(filters);
    this.tableData = this.holidayService.getFilteredHolidays(filters).map(holiday => ({ ...holiday }));
  }

  filterByYear(year: number) {
    const firstDayOfYear = new Date(`${year}-01-01T00:00:00`);
    const lastDayOfYear = new Date(`${year}-12-31T23:59:59`);
    this.applyFilters({
      filters: {
        filterList: [
          {
            field: 'date',
            operator: FilterOperator.Between,
            value: [firstDayOfYear.toISOString(), lastDayOfYear.toISOString()],
          },
        ],
      },
    });
  }

  deleteHolidays(id?: number) {
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          title: 'holidays.deleteTitle',
          description: 'holidays.deleteMessage',
        },
      })
      .afterClosed()
      .pipe(filter(val => !!val))
      .subscribe(() => {
        if (id) {
          this.holidayService.deleteHolidays([id]).subscribe(() => this.loadHolidays());
        } else {
          const selected = this.selectedRows().map(({ id }) => id);
          this.holidayService.deleteHolidays(selected).subscribe(() => this.loadHolidays());
        }
      });
  }

  handleAction({ actionId, row }: ActionEventType<HolidayActionsEnum, HolidayRow>) {
    switch (actionId) {
      case HolidayActionsEnum.VIEW:
        this.addEditHoliday(row);
        break;
      case HolidayActionsEnum.EDIT:
        this.addEditHoliday(row);
        break;
      case HolidayActionsEnum.DELETE:
        this.deleteHolidays(row.id);
        break;
    }
  }
}
