import { cipo } from 'cipo';

    cipo.controller('fmUploadController',
        function ($scope, MODAL, Upload, $timeout, action, Message, $mdDialog, Model, acceptedMimeTypes, fileService, ModuleDictionaries) {

            $scope.disabledBtn = true;
            $scope.unsupportedMimeType = false;
            $scope.message = "Upload";
            $scope.acceptedMimeTypes = acceptedMimeTypes || [];
            $scope.modalInfo = MODAL;
            $scope.mimeTypes = [];

        $scope.isCreateVersion = true;

        var generateFileUrl = action ? action.generateFileIdUrl : "";
        var startUploadUrl = action ? action.startUploadUrl : "";
        var uploadChunkUrl = action ? action.uploadChunkUrl : "";
        var saveFileInfoUrl = action ? action.saveFileInfoUrl : "";
        var uploadUrl = action ? action.uploadUrl : "";

        var otherParams = action ? action.urlParams : otherParams;
        var headerParams = action ? action.headerParams : {};

        $scope.information = action ? action.information : "";
        $scope.accept = action ? action.accept : "*";
        $scope.multiple = action ? action.multiple : true;

            ModuleDictionaries.init();

            ModuleDictionaries.get_dataSource("mimetypes")
                .then(function () {
                    $scope.mimeTypes = ModuleDictionaries.dataSources.mimetypes.data;
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                })

            $scope.files = [];
            $scope.isUploadInProgress = false;
            $scope.$watch('files', function (n, o) {
                $scope.upload($scope.files);
            });

        $scope.filesToUpload = [];
        $scope.fileUploaded = 0;
        var isModified = false;
        var urlParamsString = "";
        if (action.urlParams)
            for (var key in action.urlParams()) {
                if (action.urlParams().hasOwnProperty(key)) {
                    urlParamsString += '&' + key + "=" + action.urlParams()[key];
                }
            }

            $scope.removeFile = function (item) {
                var index = $scope.filesToUpload.indexOf(item);
                $scope.filesToUpload.splice(index, 1);
            }

            $scope.upload = function (files) {
                $scope.disabledBtn = true;
                upload(files);
            }

        var upload = function (files) {

            if (Object.prototype.toString.call(files) === '[object Array]') {
                // multiple upload
                $scope.filesToUpload = files;
            } else {
                // single file
                $scope.filesToUpload = [];
                $scope.filesToUpload.push(files);
                files = [files];
            }

                var notSupported = false;

                files.forEach(f => {
                    fileService.setMimeTypeFromExt(f);

                    if ($scope.mimeTypes.filter(function (mimeType) { return mimeType.description == f.mimeType }).length == 0) {
                        f.notAllowed = true;
                        notSupported = true;
                        $scope.unsupportedMimeType = true;

                        Message.error(`${f.name || ''} has ${f.mimeType || fileService.getExtension(f) || ''} extension which is not supported by CIPO. Please contact support@cipo.cloud`);

                        return;
                    }
                });

                // Check if we found anot supported file
                if (notSupported) {
                    $scope.disabledBtn = false;
                    return;
                }

                $scope.unsupportedMimeType = false;

            $scope.fileUploaded = 0;
            $scope.isResumeSupported = Upload.isResumeSupported();
            var invalidFiles = [];

            if ($scope.acceptedMimeTypes && $scope.acceptedMimeTypes.length > 0) {
                invalidFiles = $scope.getInvalidFiles(files);
                if (invalidFiles.length) {
                    $scope.handleInvalidFiles(files, invalidFiles);
                }
            }

            // console.log("files to upload", files);

            var generateFileId = function (file) {
                Model[generateFileUrl.method](generateFileUrl)
                    .then(function (r) {
                        uploadFile(file, r);
                    })
                    .catch(function (e) {
                        Message.dberror(e);
                    })
            }

            var uploadFile = function (file, r) {
                var physicalFileId = r.physicalFileId;

                file.message = "Uploading file...";

                var finalizeUpload = function (file, fileId, chunksNumber) {
                    var physicalFileId = fileId;

                    var params = {
                        mimeType: file.mimeType,
                        isCreateVersion: $scope.isCreateVersion,
                        fileId: physicalFileId,
                        totalBlocks: chunksNumber,
                        parentId: action.urlParams().parentId ? action.urlParams().parentId : -1,
                        entityInstanceId: action.urlParams().entityInstanceId ? action.urlParams().entityInstanceId : 0,
                        roleId: action.urlParams().roleId ? action.urlParams().roleId : 0,
                    };

                    Model[saveFileInfoUrl.method](saveFileInfoUrl, { url: params, urltype: 'obj', body: { filename: file.name, bytes: file.size } }, { headers: headerParams })
                        .then(function (r) {
                        })
                        .catch(function (e) {
                            Message.dberror(e);
                        })
                }

                if (!file.$error) {
                    if (file.size <= 62914560) { // 60 MB

                        var urlParamString;
                        if (!action || action.mimeTypeInUrl) {
                            urlParamString = '?mimeType=' + file.mimeTypeQueryString + urlParamsString;
                        } else {
                            urlParamString = urlParamsString;
                        }

                        urlParamString = urlParamString + '&isCreateVersion=' + $scope.isCreateVersion;

                        $scope.uploadObject = Upload.upload({
                            method: uploadUrl.method,
                            url: uploadUrl.toString() + urlParamString,
                            data: {
                                file: file
                            }
                        });
                    }
                    else {
                        $scope.uploadObject = Upload.upload({
                            method: uploadChunkUrl.method,
                            url: uploadChunkUrl.toString() + '?fileId=' + physicalFileId,
                            data: {
                                file: file
                            },
                            resumeChunkSize: '50MB',
                            resumeSizeUrl: startUploadUrl.toString() + '?name=' + encodeURIComponent(file.name),
                            resumeSizeResponseReader: function (data) { return data.size; }
                        });
                    }

                    $scope.isUploadInProgress = Upload.isUploadInProgress();
                    file.inProgress = true;
                    $scope.uploadObject
                        .then(function (r) {
                            $timeout(function () {

                                if (file.size > 62914560) // 60 MB
                                {
                                    // calculate the total number of chuncks, every chunck should have 50 MB
                                    var chunksNumber = Math.ceil(file.size / 52428800);
                                    // var chunksNumber = $scope.uploadObject.$$state.value.data;
                                    finalizeUpload(file, physicalFileId, chunksNumber);
                                }
                                file.message = "File uploaded successfully.";
                                $scope.isUploadInProgress = false;
                                file.inProgress = false;
                                isModified = true;
                                $scope.fileUploaded++;
                            });
                        }, function (response) {
                            Message.dberror(response);
                            $scope.fileUploaded++;
                            if (response.status > 0) {
                                file.message = "An error has occured. The file couldn't be uploaded.";
                                file.error = true;
                            }
                        }, function (evt) {
                            file.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        });
                }
            }

            if (files && files.length) {
                angular.forEach(files, function (file) {
                    if (!invalidFiles.includes(file)) {
                        generateFileId(file);
                    }
                });
            }
        };

        $scope.getInvalidFiles = function (files) {
            var invalidFiles = [];
            files.forEach(x => {
                if (!$scope.acceptedMimeTypes.includes(x.type)) {
                    invalidFiles.push(x);
                }
            });
            return invalidFiles;
        }

        $scope.handleInvalidFiles = function (files, invalidFiles) {
            files.forEach(x => {
                if (invalidFiles.includes(x)) {
                    // Set error
                    var fileExt = `.${x.name.split('.').pop()}`;
                    x.message = `This module does not support ${fileExt} files.`;
                    x.error = true;
                    $scope.fileUploaded++;
                }
            });
        }

        $scope.closeModal = function () {
            if (isModified) $mdDialog.hide();
            else $mdDialog.cancel();
        };

        $scope.cancelUpload = function () {
            $scope.uploadObject.abort();
            $mdDialog.cancel();
        };
    });
