import { Component, AfterViewInit, signal, input, effect, inject } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { GridStackOptions } from 'gridstack';
import { GridstackModule, GridstackItemComponent, nodesCB } from 'gridstack/dist/angular';

import { GridsterItemComponent } from './gridster-item/gridster-item.component';
import { FIELD_ICONS, GRIDSTACK_CONSTANTS } from '../../../definition/form/common';
import { CipoFormModule } from '../../../../shared/modules/forms.module';
import { FieldCardComponent } from './field-card/field-card.component';
import { FieldTypesEnum } from '../../../../models/module/fields/enums';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../../shared/services/user.service';
import { AddEditFieldComponent } from './add-edit-field/add-edit-field.component';

@Component({
  selector: 'app-form-builder',
  standalone: true,
  imports: [GridstackModule, GridsterItemComponent, CipoFormModule, MatMenuModule, FieldCardComponent],
  templateUrl: './form-builder.component.html',
  styleUrl: './form-builder.component.scss',
})
export class FormBuilderComponent implements AfterViewInit {
  dialog = inject(MatDialog);
  userService = inject(UserService);
  FIELD_TYPES = FieldTypesEnum;
  FIELD_ICONS = FIELD_ICONS;
  gridOptions: GridStackOptions = {
    float: true,
    cellHeight: GRIDSTACK_CONSTANTS.cellHeight * 0.75,
    margin: GRIDSTACK_CONSTANTS.margin,
  };
  viewInitialized = signal(false);

  fields = input<GridstackItemComponent[]>([]);
  searchCriteria = signal('');
  showContractFields = signal(false);

  constructor() {
    effect(() => {
      console.log('searchCriteria', this.searchCriteria());
    });
    effect(() => console.log('showContractFields', this.showContractFields()));
  }

  ngAfterViewInit(): void {
    this.viewInitialized.set(true);
  }

  onChange(event: nodesCB) {
    console.log(event.nodes);
  }

  addNewField(type: FieldTypesEnum) {
    this.dialog.open(AddEditFieldComponent, {
      panelClass: ['cipo-dialog', 'classic'],
      ...this.userService.getResponsiveDialogSize().md,
      data: type,
    });
  }
}
