import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AppState } from '../../../../app.state';
import { MeasurementSystem } from '../../../models/module/fields/enums/measurementSystem';
import { TenantSettingsEnum, TenantSettingValue } from '../../../module/instance/tenant-settings/tenant-settings.model';
import { tenantSelectors } from '../../../store/tenant';
import { Measurements, MeasurementType } from './measurement.model';

@Pipe({
  name: 'measurement',
})
export class MeasurementPipe implements PipeTransform {
  store = inject(Store<AppState>);

  transform(value: number | string, measurementType: MeasurementType): Observable<string> {
    return this.store.select(tenantSelectors.getSettingValue(TenantSettingsEnum.measurement_system)).pipe(
      map((measurementSystem: TenantSettingValue) => {
        let result = '';
        if (value && (typeof measurementSystem === 'string' || typeof measurementSystem === 'number')) {
          const measurementSystemEnumValue =
            typeof measurementSystem === 'string' ? measurementSystem : MeasurementSystem[measurementSystem];
          const measurement = Measurements[measurementSystemEnumValue][measurementType] ?? '';
          result = `${value} ${measurement}`;
        }
        return result;
      }),
    );
  }
}
