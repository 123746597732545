import { Injectable } from '@angular/core';
import { FormItem } from './form-builder.interface';
import { FormFieldModel } from '../../../../models/module/fields/main';
import { DisplayType, FieldTypeEnum } from '../../../../models/module/fields/enums';
import { CipoFieldTypes, CipoFieldValue } from '../../../../shared/components/fields/common';

@Injectable()
export class FormBuilderService {
  private getFormFieldType(field: FormFieldModel): CipoFieldTypes {
    const { displayType, type } = field;
    switch (type) {
      case FieldTypeEnum.Attachment:
        return CipoFieldTypes.Attachment;
      case FieldTypeEnum.YesNo:
        return CipoFieldTypes.Checkbox;
      case FieldTypeEnum.Date:
        return CipoFieldTypes.DateTime;
      case FieldTypeEnum.Text:
        switch (displayType) {
          case DisplayType.RichText:
            return CipoFieldTypes.RichText;
          case DisplayType.Select:
            return CipoFieldTypes.Select;
          case DisplayType.Radio:
            return CipoFieldTypes.Listbox;
          default:
            return CipoFieldTypes.Text;
        }
      case FieldTypeEnum.Number:
        return CipoFieldTypes.Number;
      case FieldTypeEnum.Annotation:
        return CipoFieldTypes.Annotation;
    }
    // to be completed

    return CipoFieldTypes.Text;
  }

  mapFieldsToFormItems(fields: FormFieldModel[]): FormItem[] {
    return fields.map(field => {
      const { id, x, y, cols, rows, value, label, hasMultipleValues, dataSources, isMandatory, annotation } = field;
      const actualType = this.getFormFieldType(field);
      return {
        id: id.toString(),
        value: annotation ?? (value as CipoFieldValue),
        fieldOptions: {
          label,
          multiple: field.displayType !== DisplayType.MultiLine && hasMultipleValues,
          multiline: field.displayType === DisplayType.MultiLine,
          type: actualType,
          options: dataSources ?? [],
          required: isMandatory,
          position: { x, y, cols, rows },
        },
      };
    });
  }
}
