import { cipo } from 'cipo';

cipo.controller('editUserController',
    function ($scope, $location, $state, User, Message, obj, operations, module, $mdDialog, userService, authService, Menu, MODAL, $timeout)
    {
        
        $scope.modalInfo = MODAL;
        $scope.sid = obj ? obj.inviteId : -1;
        $location.search({ sid: $scope.sid });
        $scope.obj = obj || null;
        $scope.user = new User(obj);
        $scope.operations = operations || {};
        $scope.module = module;
        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || module.roles[0];
            $scope.user.selectedRoleId = $scope.selectedRole.key;
        };
        $scope.changeSelectedRole();
        $scope.openMenu = function ($mdMenu, ev) {
            $timeout(function () {
                $scope.focusSearch = true;
                $timeout(function () {
                    $scope.focusSearch = false;

            }, 300);
        }, 300);
        $mdMenu.open(ev);
    };

    
    Object.defineProperty($scope.user, 'clearForm', {
        get: function () {
            return angular.equals($scope.user.properties.roleIds.sort(), $scope.user.form.storedData.roleIds.sort())
                && $scope.user.properties.org == $scope.user.form.storedData.org
                && !$scope.user.isMainRolesOrderChanged;
        }
    });
    $scope.search = function (item) {
        if (!$scope.user.searchContract || ((item.contractName || "").toLowerCase().indexOf($scope.user.searchContract.toLowerCase()) != -1) || ((item.contractNumber||"").toLowerCase().indexOf($scope.user.searchContract.toLowerCase()) != -1)) {
            return true;
        }
        return false;
    };

    $scope.message = "";

    $scope.$on('tab-change', function (event, tabEvent)
    {
        if ($scope.user.clearForm && !$scope.user.isDirtyAssignments)
        {
            tabEvent.accept();
        } else
        {
            // capture the index of the tab cliked
            $scope.navToIndex = tabEvent.index;
            // set the warning params
            $scope.user.hightlight = true;
            $scope.user.tabWarning = true;

            $scope.disabledBtn = true;
            // prevent tab change
            tabEvent.reject();
        }
    });

    $scope.closingModal = false;

    $scope.revertTabInfo = function ()
    {
        if (!$scope.closingModal){
            $scope.user.tabWarning = false;
            $scope.user.hightlight = false;
            $scope.disabledBtn = false;
            if ($scope.selectedIndex == 0) {
                $scope.user.form.restore_Data();
                $scope.user.form.set_Data($scope.user.properties);
                $scope.user.isMainRolesOrderChanged = false; 
            } else {
                $scope.user.revertAssignmentsChanges();
            }

            if (!$scope.changingStatus)
                $scope.selectedIndex = $scope.navToIndex;
            else
                $scope.changeStatus();
            
        } else {
            $scope.close(true);
        }
    };

    $scope.cancelAction = function ()
    {
        $scope.user.tabWarning = false;
        $scope.user.hightlight = false;
        $scope.disabledBtn = false;
        if ($scope.changingStatus)
        {
            $scope.user.disabled = !$scope.user.disabled;
            $scope.changingStatus = false;
        }
        if ($scope.closingModal)
            $scope.closingModal = false;
    };

    $scope.updateTabInfo = function ()
    {
        $scope.user.hightlight = false;
        $scope.actionBtnDisabled = true;
        $scope.user.save()
            .then(function (r)
            {
                $scope.user.tabWarning = false;
                if (!$scope.closingModal && !$scope.changingStatus)
                {
                    $scope.selectedIndex = $scope.navToIndex;
                } else if ($scope.changingStatus)
                {
                    $scope.changeStatus();
                } else
                {
                    $scope.close(true);
                }

            })
            .catch(function (e)
            {
                console.error(e);
            })
            .finally(function ()
            {

                $scope.disabledBtn = false;
                $scope.actionBtnDisabled = false;
            });
    };

    $scope.changeStatus = function ()
    {
        if ($scope.user.clearForm)
        {
            if ($scope.user.properties.status == 2)
            {
                $scope.deactivateUser();
            } else
            {
                $scope.activateUser();
            }
        }
        else
        {
            $scope.user.tabWarning = true;
            $scope.user.hightlight = true;
            $scope.changingStatus = true;
        }
    };

    $scope.deactivateUser = function ()
    {
        $scope.disabledBtn = true;
        $scope.message = "Deactivating user..."
        $scope.user.disable()
            .then(function (result)
            {
                $scope.user.properties.status = 0;
                if ($scope.user.properties.id == userService.system.userdata.id)
                {
                    $mdDialog.cancel();
                    authService.logout();
                } else
                {
                    Message.info('User deactivated successfully');
                    $scope.isModified = true;
                }
            })
            .catch(function (e)
            {
                console.error(e);
                Message.dberror(e);
            })
            .finally(function ()
            {
                $scope.disabledBtn = false;
                $scope.message = "";
            });

    };

    $scope.activateUser = function ()
    {
        $scope.disabledBtn = true;
        $scope.message = "Activating user...";
        $scope.user.enable()
            .then(function (result)
            {
                $scope.user.properties.status = 2;
                Message.info('User activated successfully');
                $scope.isModified = true;
            })
            .catch(function (reject)
            {
                console.error(reject);
                Message.dberror(e);
            })
            .finally(function ()
            {
                $scope.disabledBtn = false;
                $scope.message = "";
            });

    };

    $scope.save = function (isAdd)
    {
        $scope.user.tabWarning = false;
        $scope.user.hightlight = false;

        if ($scope.selectedIndex == 0) {
            $scope.disabledBtn = true;
            $scope.message = "Saving user...";
            $scope.user.save(isAdd)
                .then(function () {
                    if (!$scope.user.properties.inviteId) {
                        if (!isAdd) $mdDialog.hide();
                        else $location.search({ sid: -1 });
                    }
                    $scope.isModified = true;
                    $scope.userSaved(true);
                })
                .catch(function (e) {
                    if (e) console.error(e);
                    else $scope.userSaved(false);
                });
        } else {
            $scope.user.save_assignments(isAdd);
        }
    };

    $scope.userSaved = function (isSaveFormValid) {
        if (!isSaveFormValid) {
            return;
        }
        if ($scope.user.properties.id === userService.system.userdata.id) {
            Menu.triggerRefresh()
                .then(function () {
                    var perm = userService.getPermissions('U');
                    if (!perm.Read) {
                        $state.go('e404');
                        $mdDialog.cancel();
                    }
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                });
        } else {
            $scope.disabledBtn = false;
        }
        $scope.message = "";
    }

    $scope.close = function (isWarning)
    {
        if ($scope.user.clearForm && !$scope.user.isDirtyAssignments || isWarning)
            if ($scope.isModified) $mdDialog.hide();
            else $mdDialog.cancel();
        else
        {
            $scope.user.hightlight = true;
            $scope.user.tabWarning = true;
            $scope.closingModal = true;
        }
    };

    $scope.actionsOptions = {
        save: $scope.save,
        close: $scope.close,
        revertTabInfo: $scope.revertTabInfo,
        updateTabInfo: $scope.updateTabInfo,
        cancelAction: $scope.cancelAction
    };
});
