import moment from 'moment';
import {
  CipoAttachmentConfig,
  CipoCheckboxConfig,
  CipoDateTimeConfig,
  CipoFieldConfig,
  CipoFieldTypes,
  CipoFieldValue,
  CipoListConfig,
  CipoNumberConfig,
  CipoTextConfig,
} from '../../shared/components/fields/common';
import { AttachmentModel, MimeTypesEnum } from '../../models/module/fields/main';

export type DemoFieldData<T = CipoFieldConfig, V = CipoFieldValue> = T & {
  name: string;
  defaultValue?: V;
};

export const demoFields: DemoFieldData[] = [
  {
    type: CipoFieldTypes.Text,
    name: '1firstName',
    label: 'First Name',
    hint: 'Enter your first name',
    defaultValue: 'John',
    fieldDescription: 'This is your first name',
  },
  {
    type: CipoFieldTypes.Text,
    name: '2lastName',
    label: 'Last Name',
    hint: 'Enter your last name',
    defaultValue: 'Doe',
    multiline: true,
  },
  {
    type: CipoFieldTypes.Text,
    name: '3message',
    label: 'Message',
    hint: 'Enter your message',
    defaultValue: ['Hello', 'World!'],
    multiple: true,
  },
  {
    type: CipoFieldTypes.Text,
    name: '4description',
    label: 'Description',
    hint: 'This is a multiline field',
    defaultValue: 'Enter your description \nThis is a multiline field',
    multiline: true,
  },
  {
    type: CipoFieldTypes.Number,
    name: '5age',
    label: 'Age',
    hint: 'Enter your age (without thousand separator)',
    defaultValue: 25,
    required: true,
  },
  {
    type: CipoFieldTypes.Number,
    name: '6price',
    label: 'Price',
    hint: 'Enter the price, 2 decimals allowed',
    defaultValue: 1003232.12,
    decimals: 2,
    isCurrency: true,
    showThousandSeparator: true,
  },
  {
    type: CipoFieldTypes.Number,
    name: '7quantity',
    label: 'Quantity',
    hint: 'Enter the quantity, 3 decimals allowed',
    defaultValue: [10],
    multiple: true,
    required: true,
    showThousandSeparator: true,
    decimals: 3,
  },
  {
    type: CipoFieldTypes.Listbox,
    name: '8options',
    label: 'Radio Options',
    defaultValue: [],
    options: [
      {
        key: 'option1',
        value: 'Option 1',
      },
      {
        key: 'option2',
        value: 'Option 2',
      },
      {
        key: 'option3',
        value: 'Option 3',
      },
    ],
    multiple: true,
    required: true,
    columns: 2,
    fieldDescription: 'Select one or more options',
    hint: 'Select one or more options',
  },
  {
    type: CipoFieldTypes.Checkbox,
    label: 'Required checkbox',
    name: '9requiredCheckbox',
    defaultValue: false,
    required: true,
    fieldDescription: 'This is a required checkbox',
    hint: 'checkbox hint',
  },
];

export const DemoTextFields: DemoFieldData<CipoTextConfig, string | string[]>[] = [
  {
    type: CipoFieldTypes.Text,
    name: '11basic',
    label: 'Basic Text Field',
  },
  {
    type: CipoFieldTypes.Text,
    name: '12firstName',
    label: 'First Name',
    hint: 'Field with max 20 characters',
    maxLength: 20,
  },
  {
    type: CipoFieldTypes.Text,
    name: '13lastName',
    label: 'Last Name',
    hint: 'Field with hint',
    defaultValue: 'John',
    required: true,
  },
  {
    type: CipoFieldTypes.Text,
    name: '14field',
    label: 'Field with description',
    fieldDescription: 'This is a field description',
  },
  {
    type: CipoFieldTypes.Text,
    name: '15prefix',
    label: 'Street Address',
    hint: 'Field with prefix and suffix icon',
    defaultValue: '123 Main St',
    prefix: 'Str: ',
    suffixIcon: 'home',
  },
  {
    type: CipoFieldTypes.Text,
    name: '16suffix',
    label: 'Street Address 2',
    hint: 'Field with prefix icon and suffix',
    prefixIcon: 'home',
    suffix: 'st',
  },
  {
    type: CipoFieldTypes.Text,
    name: '17multiple',
    label: 'Multiple values',
    hint: 'Multiple inputs, required',
    defaultValue: ['First Value', 'Second Value'],
    multiple: true,
    required: true,
  },
  {
    type: CipoFieldTypes.Text,
    name: '18textdescription',
    label: 'Text Description',
    hint: 'This is a multiline field',
    defaultValue: 'Enter your description \nThis is a multiline field',
    multiline: true,
  },
  {
    type: CipoFieldTypes.Text,
    name: '19textdescription',
    label: 'Text Description',
    hint: 'This is a multiline field, required',
    defaultValue: 'Enter your description \nThis is a multiline field',
    required: true,
    multiline: true,
  },
  {
    type: CipoFieldTypes.Text,
    name: '20textdescription',
    label: 'Text Description readonly',
    hint: 'This is a multiline field',
    defaultValue: 'Enter your description \nThis is a multiline field',
    multiline: true,
    readonly: true,
  },
  {
    type: CipoFieldTypes.Text,
    name: '21multiple',
    label: 'Multiple values',
    hint: 'Multiple inputs, readonly required',
    defaultValue: ['First Value', 'Second Value'],
    multiple: true,
    readonly: true,
    required: true,
  },
  {
    type: CipoFieldTypes.Text,
    name: '22address',
    label: 'Street Address readonly',
    hint: 'Field with prefix and suffix icon',
    defaultValue: '123 Main St',
    prefix: 'Str: ',
    suffixIcon: 'home',
    readonly: true,
  },
];

export const DemoNumberFields: DemoFieldData<CipoNumberConfig, number | number[]>[] = [
  {
    type: CipoFieldTypes.Number,
    name: '10basic',
    label: 'Basic Number Field',
  },
  {
    type: CipoFieldTypes.Number,
    name: '11age',
    label: 'Age',
    hint: 'Enter your age (integer)',
    fieldDescription: "The age is required, and doesn't have thousand separator",
    required: true,
  },
  {
    type: CipoFieldTypes.Number,
    name: '12separator',
    label: 'Number with thousand separator',
    hint: 'This is a simple number field with thousand separator',
    defaultValue: 2325,
    showThousandSeparator: true,
  },
  {
    type: CipoFieldTypes.Number,
    name: '13decimal',
    label: 'Number with decimals',
    hint: 'This is a simple decimal field without thousand separator',
    defaultValue: 2325.32,
    decimals: 2,
  },
  {
    type: CipoFieldTypes.Number,
    name: '13price',
    label: 'Price',
    hint: 'Field with prefix and suffix icon',
    defaultValue: 1003232.12,
    decimals: 2,
    prefix: '$',
    suffixIcon: 'home',
    showThousandSeparator: true,
  },
  {
    type: CipoFieldTypes.Number,
    name: '14quantity',
    label: 'Quantity',
    hint: 'Enter the quantity, 1 decimals allowed',
    defaultValue: [10],
    multiple: true,
    required: true,
    showThousandSeparator: true,
    decimals: 1,
  },
  {
    type: CipoFieldTypes.Number,
    name: '15price',
    label: 'Price readonly',
    hint: 'Field with prefix and suffix icon',
    defaultValue: 1003232.12,
    decimals: 2,
    prefix: '$',
    suffixIcon: 'home',
    readonly: true,
    showThousandSeparator: true,
  },
  {
    type: CipoFieldTypes.Number,
    name: '16quantity',
    label: 'Quantity readonly',
    hint: 'Field with prefix and suffix icon',
    defaultValue: [10.1, 22.4, 12221],
    decimals: 1,
    multiple: true,
    readonly: true,
    showThousandSeparator: true,
  },
];

export const DemoDateTimeFields: DemoFieldData<CipoDateTimeConfig, string | string[]>[] = [
  {
    type: CipoFieldTypes.DateTime,
    name: '1dateTime',
    label: 'Basic Date Time',
    hint: 'Select a date and time',
    pickerType: 'both',
  },
  {
    type: CipoFieldTypes.DateTime,
    name: '20date',
    label: 'Date only',
    hint: 'Select a date',
    pickerType: 'calendar',
  },
  {
    type: CipoFieldTypes.DateTime,
    name: '21date',
    label: 'Date only',
    hint: 'Select a date until today',
    pickerType: 'calendar',
    fieldDescription: 'You can select any date from past. MaxDate is set to today',
    maxDate: moment().toISOString(),
  },
  {
    type: CipoFieldTypes.DateTime,
    name: '22date',
    label: 'Date only',
    defaultValue: moment().add(30, 'days').toISOString(),
    hint: 'Select a date from today',
    fieldDescription: 'You can select any date from future. MinDate is set to today',
    minDate: moment().toISOString(),
  },
  {
    type: CipoFieldTypes.DateTime,
    name: '3time',
    label: 'Time only',
    hint: 'Select a time',
    pickerType: 'timer',
  },
  {
    type: CipoFieldTypes.DateTime,
    name: '40range',
    label: 'Date Time Range',
    hint: 'Select a date and time range',
    pickerType: 'both',
    selectMode: 'range',
  },
  {
    type: CipoFieldTypes.DateTime,
    name: '41range',
    label: 'Date Range',
    hint: 'Select a date range. Max range is 3 day',
    selectMode: 'range',
    maxRange: 3,
  },
  {
    type: CipoFieldTypes.DateTime,
    name: '42range',
    label: 'Date Range',
    hint: 'Select a date range until today. Max range is 3 day',
    fieldDescription: 'You can select a date range with max 3 days. The last date is today',
    selectMode: 'range',
    maxRange: 3,
    maxDate: moment().toISOString(),
  },
  {
    type: CipoFieldTypes.DateTime,
    name: '5readonly',
    label: 'Date Time readonly',
    pickerType: 'both',
    readonly: true,
    defaultValue: '2021-01-01T00:00:00.000Z',
  },
  {
    type: CipoFieldTypes.DateTime,
    name: '6readonly',
    label: 'Date Time Range readonly',
    pickerType: 'both',
    selectMode: 'range',
    readonly: true,
    defaultValue: ['2021-01-01T00:00:00.000Z', '2021-01-02T00:00:00.000Z'],
  },
  {
    type: CipoFieldTypes.DateTime,
    name: '7readonly',
    label: 'Time readonly',
    pickerType: 'timer',
    readonly: true,
    defaultValue: moment().toISOString(),
  },
  {
    type: CipoFieldTypes.DateTime,
    name: '8readonly',
    label: 'Time range readonly',
    selectMode: 'range',
    pickerType: 'timer',
    readonly: true,
    defaultValue: [moment().toISOString(), moment().add(400, 'minute').toISOString()],
  },
];

export const DemoListboxFields: DemoFieldData<CipoListConfig, string | string[]>[] = [
  {
    type: CipoFieldTypes.Listbox,
    name: '1options',
    label: 'Single listbox (required)',
    defaultValue: [],
    options: [
      {
        key: 'option1',
        value: 'Option 1',
      },
      {
        key: 'option2',
        value: 'Option 2',
      },
      {
        key: 'option3',
        value: 'Option 3',
      },
    ],
    required: true,
    hint: 'Select one option',
  },
  {
    type: CipoFieldTypes.Listbox,
    name: '2options',
    label: 'Multiple listbox',
    defaultValue: ['option1', 'option2'],
    options: [
      {
        key: 'option1',
        value: 'Option 1',
      },
      {
        key: 'option2',
        value: 'Option 2',
      },
      {
        key: 'option3',
        value:
          'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      },
    ],
    columns: 2,
    multiple: true,
    fieldDescription: 'Options in 2 columns',
    hint: 'Select one or more options',
  },
  {
    type: CipoFieldTypes.Listbox,
    name: '3options',
    label: 'Multiple listbox readonly',
    defaultValue: ['option1', 'option2'],
    options: [
      {
        key: 'option1',
        value: 'Option 1',
      },
      {
        key: 'option2',
        value: 'Option 2',
      },
      {
        key: 'option3',
        value: 'Option 3',
      },
    ],
    multiple: true,
    readonly: true,
    columns: 2,
    hint: 'Select one or more options',
  },
];

export const DemoCheckboxFields: DemoFieldData<CipoCheckboxConfig, boolean>[] = [
  {
    type: CipoFieldTypes.Checkbox,
    name: '1basicCheckbox',
    label: 'Basic checkbox',
    defaultValue: true,
    hint: 'checkbox hint',
  },
  {
    type: CipoFieldTypes.Checkbox,
    label: 'Required checkbox',
    name: '2requiredCheckbox',
    required: true,
    fieldDescription: 'This is a required checkbox',
  },
  {
    type: CipoFieldTypes.Checkbox,
    label: 'Readonly positive checkbox',
    name: '3disabledCheckbox',
    defaultValue: true,
    readonly: true,
    hint: 'required checkbox, default checked',
  },
  {
    type: CipoFieldTypes.Checkbox,
    label: 'Readonly negative checkbox',
    name: '4disabledCheckbox',
    defaultValue: false,
    readonly: true,
    hint: 'required checkbox, default not checked',
  },
  {
    type: CipoFieldTypes.Checkbox,
    label:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    name: '5longCheckbox',
    defaultValue: true,
    hint: 'checkbox hint',
  },
  {
    type: CipoFieldTypes.Checkbox,
    label:
      'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    name: '6longCheckbox',
    fieldDescription: 'This is a checkbox with long text',
    defaultValue: true,
    hint: 'checkbox hint',
  },
];

export const DemoFieldsWithAttachment: DemoFieldData<CipoAttachmentConfig, AttachmentModel[]>[] = [
  {
    type: CipoFieldTypes.Attachment,
    name: '1attachment',
    label: 'Attachment',
    hint: 'Upload your file',
    defaultValue: [
      {
        id: 1,
        version: 1,
        versionId: 1,
        mimeType: MimeTypesEnum.XLSX,
        mimeTypeId: 1,
        parentId: 0,
        length: 12345,
        name: 'demo excel',
        order: 1,
      },
    ],
  },
  {
    type: CipoFieldTypes.Attachment,
    name: '2attachment',
    label: 'Attachment with description',
    hint: 'Upload your file',
    required: true,
    fieldDescription: 'This is an attachment field',
    defaultValue: [
      {
        id: 1,
        version: 1,
        versionId: 1,
        mimeType: MimeTypesEnum.DOCX,
        mimeTypeId: 1,
        parentId: 0,
        length: 12345,
        name: 'demo word',
        order: 1,
      },
    ],
  },
  {
    type: CipoFieldTypes.Attachment,
    name: '3attachment',
    label: 'Attachment with multiple save',
    hint: 'And one file with big name',
    defaultValue: [
      {
        id: 1,
        version: 1,
        versionId: 1,
        mimeType: MimeTypesEnum.PNG,
        mimeTypeId: 1,
        parentId: 0,
        length: 12345,
        name: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        order: 1,
        thumbnail50:
          '/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAcFBQYFBAcGBQYIBwcIChELCgkJChUPEAwRGBUaGRgVGBcbHichGx0lHRcYIi4iJSgpKywrGiAvMy8qMicqKyr/2wBDAQcICAoJChQLCxQqHBgcKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKir/wAARCAAyADIDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD6AgvLaGII14ZiP45MFj+QFSf2ja/890/KozM5xi/I9f3HWrK3kIUBpCxA5Ow8/pT0DUi/tG1/57p+VH9o2v8Az3T8qm+2wf3z/wB8n/Cj7bB/fP8A3yf8KNA1If7Rtf8Anun5Uf2ja/8APdPyqb7bB/fP/fJ/wo+2wf3z/wB8n/CjQNSH+0bX/nun5UVN9tg/vn/vk/4UUaBqMjglSJVZ2kZRgu2Mt7nHFO8p/T9adawRQIVgZmGeS0hc5x6k1NSAr+U/p+tHlP6frViigCv5T+n60eU/p+tWKKAK/lP6frRViigCvZPaNBiw8vylwB5QwvT29qsVRt9YsbhCyzrGPSQhSePQ1N/aNl/z9wf9/BQBYoqv/aNl/wA/cH/fwUf2jZf8/cH/AH8FAFiiq/8AaNl/z9wf9/BR/aNl/wA/cH/fwUAWKKr/ANo2X/P3B/38FFADfKj/ALi/lR5Uf9xfyoooAPKj/uL+VHlR/wBxfyoooAPKj/uL+VHlR/3F/KiigA8qP+4v5UUUUAf/2Q==',
      },
      {
        id: 1,
        version: 1,
        versionId: 1,
        mimeType: MimeTypesEnum.XLSX,
        mimeTypeId: 1,
        parentId: 0,
        length: 12345,
        name: 'excel xlsx',
        order: 1,
        thumbnail50:
          '/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAcFBQYFBAcGBQYIBwcIChELCgkJChUPEAwRGBUaGRgVGBcbHichGx0lHRcYIi4iJSgpKywrGiAvMy8qMicqKyr/2wBDAQcICAoJChQLCxQqHBgcKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKir/wAARCAAyADIDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD6AgvLaGII14ZiP45MFj+QFSf2ja/890/KozM5xi/I9f3HWrK3kIUBpCxA5Ow8/pT0DUi/tG1/57p+VH9o2v8Az3T8qm+2wf3z/wB8n/Cj7bB/fP8A3yf8KNA1If7Rtf8Anun5Uf2ja/8APdPyqb7bB/fP/fJ/wo+2wf3z/wB8n/CjQNSH+0bX/nun5UVN9tg/vn/vk/4UUaBqMjglSJVZ2kZRgu2Mt7nHFO8p/T9adawRQIVgZmGeS0hc5x6k1NSAr+U/p+tHlP6frViigCv5T+n60eU/p+tWKKAK/lP6frRViigCvZPaNBiw8vylwB5QwvT29qsVRt9YsbhCyzrGPSQhSePQ1N/aNl/z9wf9/BQBYoqv/aNl/wA/cH/fwUf2jZf8/cH/AH8FAFiiq/8AaNl/z9wf9/BR/aNl/wA/cH/fwUAWKKr/ANo2X/P3B/38FFADfKj/ALi/lR5Uf9xfyoooAPKj/uL+VHlR/wBxfyoooAPKj/uL+VHlR/3F/KiigA8qP+4v5UUUUAf/2Q==',
      },
    ],
    readonly: true,
    showMultipleSave: true,
  },
  {
    type: CipoFieldTypes.Attachment,
    name: '4attachment',
    label: 'Attachment with thumbnail',
    hint: 'Upload your file',
    showMultipleSave: true,
    defaultValue: [
      {
        id: 169968,
        version: 1,
        versionId: 185079,
        mimeType: MimeTypesEnum.EMAIL,
        mimeTypeId: 1,
        parentId: 0,
        length: 12345,
        name: 'image jpeg, no thumbnail',
        order: 1,
      },
    ],
  },
];

export const DemoAttachments: AttachmentModel[] = [
  {
    id: 1,
    version: 1,
    versionId: 1,
    mimeType: MimeTypesEnum.XLSX,
    mimeTypeId: 1,
    parentId: 0,
    length: 12345,
    name: 'demo excel',
    order: 1,
  },
  {
    id: 2,
    version: 2,
    versionId: 2,
    mimeType: MimeTypesEnum.DOCX,
    mimeTypeId: 1,
    parentId: 0,
    length: 12345,
    name: 'demo word',
    order: 1,
  },
  {
    id: 3,
    version: 3,
    versionId: 3,
    mimeType: MimeTypesEnum.PNG,
    mimeTypeId: 1,
    parentId: 0,
    length: 12345,
    name: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    order: 1,
    thumbnail50:
      '/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAcFBQYFBAcGBQYIBwcIChELCgkJChUPEAwRGBUaGRgVGBcbHichGx0lHRcYIi4iJSgpKywrGiAvMy8qMicqKyr/2wBDAQcICAoJChQLCxQqHBgcKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKir/wAARCAAyADIDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD6AgvLaGII14ZiP45MFj+QFSf2ja/890/KozM5xi/I9f3HWrK3kIUBpCxA5Ow8/pT0DUi/tG1/57p+VH9o2v8Az3T8qm+2wf3z/wB8n/Cj7bB/fP8A3yf8KNA1If7Rtf8Anun5Uf2ja/8APdPyqb7bB/fP/fJ/wo+2wf3z/wB8n/CjQNSH+0bX/nun5UVN9tg/vn/vk/4UUaBqMjglSJVZ2kZRgu2Mt7nHFO8p/T9adawRQIVgZmGeS0hc5x6k1NSAr+U/p+tHlP6frViigCv5T+n60eU/p+tWKKAK/lP6frRViigCvZPaNBiw8vylwB5QwvT29qsVRt9YsbhCyzrGPSQhSePQ1N/aNl/z9wf9/BQBYoqv/aNl/wA/cH/fwUf2jZf8/cH/AH8FAFiiq/8AaNl/z9wf9/BR/aNl/wA/cH/fwUAWKKr/ANo2X/P3B/38FFADfKj/ALi/lR5Uf9xfyoooAPKj/uL+VHlR/wBxfyoooAPKj/uL+VHlR/3F/KiigA8qP+4v5UUUUAf/2Q==',
  },
  {
    id: 4,
    version: 4,
    versionId: 4,
    mimeType: MimeTypesEnum.PNG,
    mimeTypeId: 1,
    parentId: 0,
    length: 12345,
    name: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    order: 1,
  },
];
export const DemoSelectFields: DemoFieldData<CipoListConfig, string | string[]>[] = [
  {
    type: CipoFieldTypes.Select,
    name: '1options',
    label: 'Single select (required)',
    defaultValue: '',
    options: [
      {
        key: 'option1',
        value: 'Option 1',
      },
      {
        key: 'option2',
        value: 'Option 2',
      },
      {
        key: 'option3',
        value: 'Option 3',
      },
    ],
    required: true,
    hint: 'Select one option',
  },
  {
    type: CipoFieldTypes.Select,
    name: '2options',
    label: 'Multiple select',
    defaultValue: ['option1', 'option2'],
    options: [
      {
        key: 'option1',
        value: 'Option 1',
      },
      {
        key: 'option2',
        value: 'Option 2',
      },
      {
        key: 'option3',
        value:
          'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      },
    ],
    columns: 2,
    multiple: true,
    fieldDescription: 'One long option',
    hint: 'Select one or more options',
  },
  {
    type: CipoFieldTypes.Select,
    name: '3options',
    label: 'Multiple select readonly',
    defaultValue: ['option1', 'option2'],
    options: [
      {
        key: 'option1',
        value: 'Option 1',
      },
      {
        key: 'option2',
        value: 'Option 2',
      },
      {
        key: 'option3',
        value: 'Option 3',
      },
    ],
    multiple: true,
    readonly: true,
    columns: 2,
    hint: 'Select one or more options',
  },
];
