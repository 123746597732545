import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeRo from '@angular/common/locales/ro';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, effect } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UIRouter, UIRouterModule } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { CustomBreakPointsModule } from 'src/app-breakpoints.module';
import { UserService } from 'src/app/shared/services/user.service';
import { DemoModule } from './app/demo/demo.module';
import { LegacyModule } from './app/legacy/legacy.module';
import { ModuleDefinitionModule } from './app/module/definition/module-definition.module';
import { ModuleInstanceModule } from './app/module/instance/module-instance.module';
import { AuthInterceptor } from './app/shared/services/auth.interceptor';
import { LocaleDateAdapter } from './app/shared/services/date.adapter';
import { LocaleService } from './app/shared/services/locale.service';
import { SystemModule } from './app/system/system.module';
import { CoreEffects, CoreReducers } from './app/store/core.store';

registerLocaleData(localeRo);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './src/assets/i18n/', '.json');
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserAnimationsModule,
    UpgradeModule,
    UIRouterUpgradeModule,
    UIRouterModule.forChild(),
    BrowserModule,
    SystemModule,
    ModuleDefinitionModule,
    ModuleInstanceModule,
    LegacyModule,
    DemoModule,
    HttpClientModule,
    CustomBreakPointsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(CoreReducers),
    EffectsModule.forRoot(CoreEffects),
    StoreDevtoolsModule.instrument(),
  ],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: (_: TranslateService, localeService: LocaleService) => localeService.getLocale(),
      deps: [TranslateService, LocaleService],
    },
    {
      provide: DateAdapter,
      useClass: LocaleDateAdapter,
    },
  ],
})
export class AppModule {
  constructor(
    private router: UIRouter,
    private userService: UserService,
  ) {
    // Remove this after migration to Angular. Then, it will be possible to add these classes directly wuth signals in template
    effect(() => {
      this.userService.appendClassesToBody({ theme: this.userService.theme$(), mode: this.userService.themeMode$() });
    });
  }
  ngDoBootstrap() {}
}
