import {
  FieldTypeEnum,
  KeyValueRestrictionModel,
  KeyValueFormattingModel,
  DisplayType,
  FieldTypesEnum,
} from '../enums';

export interface FormFieldModel {
  id: number;
  name?: string;
  label?: string;
  hasMultipleValues?: boolean;
  type?: FieldTypeEnum;
  displayType?: DisplayType;
  restrictions?: KeyValueRestrictionModel[];
  formattings?: KeyValueFormattingModel[];
  isSystem?: boolean;
  isMandatory?: boolean;
  inUse?: boolean;
  fieldOrigin?: number;
  x?: number;
  y?: number;
  cols?: number;
  rows?: number;
  triggeredFields?: unknown[];
  value?: FormFieldValueType;
  isParent?: boolean;
  parentHasDefaultValue?: boolean;
  childFieldIds?: unknown[];
  isFilter?: boolean;
  aggregateValue?: unknown;
  expression?: unknown;
  weatherDateId?: number;
  screensId?: number[];
  usedOnWeather?: boolean;
  relationId?: number;
  annotation?: string;
  // will remove these
  previousActionInstanceId?: number;
  currentDateTime?: boolean;
  typeId?: number;
  displayTypeId?: number;
  _Restrictions?: number[];
  _Formattings?: number[];
  dataSourceId?: number;
  dataSources?: FieldDataSourceModel[];
}

export interface FieldDataSourceModel {
  isDisabled: boolean;
  key: string;
  value: string;
  order: number;
}

export interface FormFieldTableValueModel {
  [key: string]: string | number | boolean | string[] | number[];
}

export type FormFieldValueType =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | FormFieldTableValueModel
  | FormFieldTableValueModel[];

export interface MappedFieldModel {
  type: FieldTypesEnum;
  name: string;
  label: string;
  value: string | string[] | number | number[] | boolean | File | File[];
  required: boolean;
  readOnly: boolean;
  maximumLength: number;
  //   numberFormat: {
  //     minVal: number;
  //     maxVal: number;
  showThousandSeparator: boolean;
  //   };
  //   dateFormat: {
  //     minDate: string;
  //     maxDate: string;
  //     interval: boolean;
  //     maxIntervalMinutes: number; // to be transformed in hours / days
  //   };
  //   attachmentFormat: {
  mimeTypes: number[];
  mimeTypesDataSource: {
    key: number;
    value: string;
    description: string;
  }[];
  //   };
}
