<table
    mat-table
    [dataSource]="dataSource"
    *ngIf="tableColumns().length"
    class="cipo-table"
    matSort
    [matSortActive]="options()?.sort?.active"
    [matSortDirection]="options()?.sort?.direction"
    (matSortChange)="tableSortChanged($event)">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="{ width: '50px' }">
            <mat-checkbox
                data-test="cy_select-all"
                (change)="$event ? toggleAllRows() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
            <mat-checkbox
                data-test="cy_select-one"
                (click)="checked()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
    </ng-container>

    @for (column of tableColumns(); track column) {
        <ng-container [matColumnDef]="column.name">
            <th
                [attr.data-test]="'cy_' + column.name + '-header'"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [class]="'align--' + column.align"
                [disabled]="!column.sortable"
                [ngStyle]="{ width: totalWidth ? (100 / totalWidth) * column.width + '%' : 'auto' }"
                [title]="column.displayName | translate">
                <span class="text--ellipsed">{{ column.displayName | translate }}</span>
            </th>

            <td
                [attr.data-test]="'cy_' + column.name + '-data'"
                mat-cell
                *matCellDef="let row"
                [ngStyle]="{
                    textAlign: column.align
                }">
                <cipo-table-cell [row]="row" [column]="column" [noDataPlaceholder]="options().noDataPlaceholder" />
            </td>
        </ng-container>
    }

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
            @if (!loading() && options().actions(row).length) {
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    (click)="$event.stopPropagation()"
                    [disabled]="row.empty"
                    data-test="cy_actions-trigger">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="cipo-menu">
                    @for (action of options().actions(row); track action.id) {
                        @if (!action.condition || action.condition(row)) {
                            <button mat-menu-item (click)="selectAction(action.id, row)" data-test="cy_action-button">
                                <mat-icon *ngIf="hasLeftIcons" class="material-icons-outlined m-r">
                                    {{ action.leftIcon }}
                                </mat-icon>
                                <span fxLayout fxLayoutAlign="space-between center" [ngClass]="{ 'm-l': hasLeftIcons }">
                                    {{ action.displayName | translate }}
                                    <mat-icon *ngIf="hasRightIcons" class="material-icons-outlined end-icon">
                                        {{ action.rightIcon }}
                                    </mat-icon>
                                </span>
                            </button>
                        }
                    }
                </mat-menu>
            }
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
    <tr
        mat-row
        (click)="!row.empty && options().clickableRows && rowClick(row)"
        *matRowDef="let row; columns: displayedColumns()"
        [ngClass]="
            !row.empty &&
            options().clickableRows && [
                'cipo-bg-hover--' + (!row.backgroundColor && (row.hoverColor ?? 'primary-200')),
                'cipo-bg--' + (row.backgroundColor ?? 'white'),
                'cursor--pointer'
            ]
        "></tr>
</table>
@if (!loading() && !dataSource.data.length) {
    <div class="cipo-empty-list p-t-xl">
        <mat-icon>search_off</mat-icon>
        <p>No data found</p>
    </div>
}
@if (options().pagination) {
    @if (serverPagination) {
        <mat-paginator
            data-test="cy_paginator"
            [style]="{ display: !dataSource.data.length ? 'none' : 'block' }"
            [pageIndex]="serverPagination.pageIndex"
            [pageSize]="serverPagination.pageSize"
            [pageSizeOptions]="serverPagination.pageSizeOptions"
            [length]="serverPagination.length"
            [disabled]="loading()"
            (page)="paginationChange($event)"></mat-paginator>
    } @else {
        <mat-paginator
            data-test="cy_paginator"
            [style]="{ display: loading() || !dataSource.data.length ? 'none' : 'block' }"
            #clientPaginator></mat-paginator>
    }
}
