import { Component, input, model } from '@angular/core';

import { CipoFieldTypes, CipoForm } from '../../../../shared/components/fields/common';
import { CipoFieldsModule, SharedComponentsModule } from '../../../../shared/modules';

@Component({
  selector: 'cipo-grid-form',
  standalone: true,
  imports: [SharedComponentsModule, CipoFieldsModule],
  templateUrl: './grid-form.component.html',
  styleUrls: ['./grid-form.component.scss'],
})
export class GridFormComponent {
  form = model.required<CipoForm>();
  editMode = input<boolean, boolean>(true, {
    transform: edit => {
      for (const control in this.form().controls) {
        this.form().controls[control].fieldData.readonly = !edit;
      }
      return edit;
    },
  });
  FIELD_TYPES = CipoFieldTypes;
}
