import { MeasurementSystem } from "../../../models/module/fields/enums/measurementSystem";

export type MeasurementType = 'temperature' | 'speed' | 'precipitation';

export const Measurements: Record<keyof typeof MeasurementSystem, Record<MeasurementType, string>> = {
  Imperial: {
    temperature: '°F',
    speed: 'mph',
    precipitation: 'in',
  },
  Metric: {
    temperature: '°C',
    speed: 'km/h',
    precipitation: 'mm',
  },
};
