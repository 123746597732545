import { Component, Inject, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CipoFormModule } from '../../../../../shared/modules/forms.module';
import { FieldTypesEnum } from '../../../../../models/module/fields/enums';
import { DateFieldTypes, NumberFieldTypes, TextFieldTypes, FIELD_ICONS } from '../../../../../shared/consts';

@Component({
  selector: 'app-add-edit-field',
  standalone: true,
  imports: [MatDialogModule, CipoFormModule, TranslateModule],
  templateUrl: './add-edit-field.component.html',
  styleUrl: './add-edit-field.component.scss',
})
export class AddEditFieldComponent {
  fb = inject(FormBuilder);

  ICONS = FIELD_ICONS;

  form = this.fb.group({
    name: ['', Validators.required],
    label: ['', Validators.required],
    type: [FieldTypesEnum.Text],
    hint: [''],
    defaultValue: [<unknown>undefined],
    multiple: [false],
    required: [false],
    readonly: [false],
    maximumLength: [<Number>undefined],
    decimals: [<Number>undefined],
    showSeparator: [false],
    isFilter: [false],
  });
  fieldTypes = signal(TextFieldTypes);

  constructor(
    private dialogRef: MatDialogRef<AddEditFieldComponent>,
    @Inject(MAT_DIALOG_DATA) private type: FieldTypesEnum,
  ) {
    console.log('type', type);
    if (type === FieldTypesEnum.Number) {
      this.form.controls.type.setValue(FieldTypesEnum.Number);
      this.fieldTypes.set(NumberFieldTypes);
    } else if (type === FieldTypesEnum.DateTime) {
      this.form.controls.type.setValue(FieldTypesEnum.DateTime);
      this.fieldTypes.set(DateFieldTypes);
    }
  }

  saveField() {
    // Save field
  }
}
