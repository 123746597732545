import { Pipe, PipeTransform } from '@angular/core';
import { CipoFroalaOptions } from '../../components/froala/interfaces';
import { GRIDSTACK_CONSTANTS } from '../../../module/definition/form/common';

@Pipe({
  name: 'froalaHeight',
  standalone: true,
})
export class FroalaHeightPipe implements PipeTransform {
  transform(options: CipoFroalaOptions, rows: number): CipoFroalaOptions {
    const froalaHeight = GRIDSTACK_CONSTANTS.cellHeight * rows - 100; // 100 for froala toolbars, the left is editor height
    return {
      ...options,
      heightMin: froalaHeight - 1,
      heightMax: froalaHeight,
    };
  }
}
