<div class="grid-container">
    <div
        *ngFor="let control of form().controls | keyvalue"
        [ngStyle]="{
            'grid-column-start': control.value.fieldData.position.x + 1,
            'grid-column-end': 'span ' + control.value.fieldData.position.cols,
            'grid-row-start': control.value.fieldData.position.y + 1,
            'grid-row-end': 'span ' + control.value.fieldData.position.rows
        }">
        @switch (control.value.fieldData.type) {
            @case (FIELD_TYPES.Text) {
                <cipo-text [control]="$any(control.value)" />
            }
            @case (FIELD_TYPES.Number) {
                <cipo-number [control]="$any(control.value)" />
            }
            @case (FIELD_TYPES.Select) {
                <cipo-select [control]="$any(control.value)" />
            }
            @case (FIELD_TYPES.Checkbox) {
                <cipo-checkbox [control]="$any(control.value)" />
            }
            @case (FIELD_TYPES.DateTime) {
                <cipo-date-time [control]="$any(control.value)" />
            }
            @case (FIELD_TYPES.RichText) {
                <app-froala [content]="$any(control.value.value)" [editmode]="editMode()" />
            }
            @case (FIELD_TYPES.Attachment) {
                <cipo-attachment [control]="$any(control.value)" />
            }
            @case (FIELD_TYPES.Annotation) {
                <app-froala [content]="$any(control.value.value)" [editmode]="false" />
            }
            @case (FIELD_TYPES.Listbox) {
                <cipo-listbox [control]="$any(control.value)" />
            }
            @default {
                <cipo-text [control]="$any(control.value)" />
            }
        }
    </div>
</div>
