import { FieldTypesEnum } from '../../models/module/fields/enums';

export const GRIDSTACK_CONSTANTS = {
  cellHeight: 100, // 100px
  margin: 3, // 3px
} as const;

export const FORM_FIELD_SIZES = {};

export const FIELD_ICONS = {
  [FieldTypesEnum.Text]: 'rtt',
  [FieldTypesEnum.Multiline]: 'notes',
  [FieldTypesEnum.Editor]: 'text_fields',
  [FieldTypesEnum.Select]: 'arrow_drop_down',
  [FieldTypesEnum.Radio]: 'radio_button_checked',
  [FieldTypesEnum.Checkbox]: 'check_box',
  [FieldTypesEnum.Number]: '123',
  [FieldTypesEnum.Percent]: 'percent',
  [FieldTypesEnum.Currency]: 'attach_money',
  [FieldTypesEnum.DateTime]: 'pending_actions',
  [FieldTypesEnum.Date]: 'date_range',
  [FieldTypesEnum.Time]: 'schedule',
  [FieldTypesEnum.Line]: 'horizontal_rule',
  [FieldTypesEnum.Attachment]: 'attachment',
  [FieldTypesEnum.Annotation]: 'edit_note',
  [FieldTypesEnum.Relation]: '',
  [FieldTypesEnum.Table]: 'calendar_view_month',
  [FieldTypesEnum.Weather]: 'cloud',
};

export const TextFieldTypes = [
  {
    key: FieldTypesEnum.Text,
    value: 'Text',
  },
  {
    key: FieldTypesEnum.Multiline,
    value: 'Multiline',
  },
  {
    key: FieldTypesEnum.Editor,
    value: 'Editor',
  },
  {
    key: FieldTypesEnum.Select,
    value: 'Select',
  },
  {
    key: FieldTypesEnum.Radio,
    value: 'Radio',
  },
];

export const NumberFieldTypes = [
  {
    key: FieldTypesEnum.Number,
    value: 'Number',
  },
  {
    key: FieldTypesEnum.Percent,
    value: 'Percent',
  },
  {
    key: FieldTypesEnum.Currency,
    value: 'Currency',
  },
];

export const DateFieldTypes = [
  {
    key: FieldTypesEnum.DateTime,
    value: 'DateTime',
  },
  {
    key: FieldTypesEnum.Date,
    value: 'Date',
  },
  {
    key: FieldTypesEnum.Time,
    value: 'Time',
  },
];
