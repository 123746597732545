import { Injectable } from '@angular/core';
import { FilterLogic, FilterModel, FilterOperator } from '../../../../models/module/grid';
import { FiltersToApply, LocalFilterModel } from '.';

@Injectable({ providedIn: 'root' })
export class LocalFilterService {
  applyOperator(value: any, operator: FilterOperator, filterValue: any): boolean {
    switch (operator) {
      case FilterOperator.Equal:
        if (Array.isArray(filterValue)) {
          return filterValue.some(val => val === value);
        }
        return value === filterValue;
      case FilterOperator.NotEqual:
        if (Array.isArray(filterValue)) {
          return filterValue.every(val => val !== value);
        }
        return value !== filterValue;
      case FilterOperator.Contains:
        return typeof value === 'string' && value.includes(filterValue);
      case FilterOperator.DoesNotContain:
        return typeof value === 'string' && !value.includes(filterValue);
      case FilterOperator.StartsWith:
        return typeof value === 'string' && value.startsWith(filterValue);
      case FilterOperator.EndsWith:
        return typeof value === 'string' && value.endsWith(filterValue);
      case FilterOperator.ContainsWithIgnoreCase:
        return typeof value === 'string' && value.toLowerCase().includes(filterValue.toLowerCase());
      case FilterOperator.LessThan:
        return value < filterValue;
      case FilterOperator.LessThanOrEqual:
        return value <= filterValue;
      case FilterOperator.MoreThan:
        return value > filterValue;
      case FilterOperator.MoreThanOrEqual:
        return value >= filterValue;
      case FilterOperator.Between:
        const valueDate = new Date(value);
        const valueStart = new Date(filterValue[0]);
        const valueEnd = new Date(filterValue[1]);
        return valueDate >= valueStart && valueDate <= valueEnd;
      case FilterOperator.On:
        return new Date(value).toDateString() === new Date(filterValue).toDateString();
      case FilterOperator.Before:
        return new Date(value) < new Date(filterValue);
      case FilterOperator.OnOrBefore:
        return new Date(value) <= new Date(filterValue);
      case FilterOperator.After:
        return new Date(value) > new Date(filterValue);
      case FilterOperator.OnOrAfter:
        return new Date(value) >= new Date(filterValue);
      case FilterOperator.NotOn:
        return new Date(value).toDateString() !== new Date(filterValue).toDateString();
      default:
        return false;
    }
  }

  getFilteredHolidays<T>(
    list: T[],
    { filters: { filterList: filters, logic = FilterLogic.AND } }: FiltersToApply,
  ): T[] {
    if (!filters || filters.length === 0) return list;

    return list.filter(item => {
      const results = filters.map(filter => {
        const field = filter.field as keyof T;
        const fieldValue = item[field];
        return this.applyOperator(fieldValue, filter.operator!, filter.value);
      });

      return logic === FilterLogic.AND ? results.every(Boolean) : results.some(Boolean);
    });
  }

  mapLocalFilters(filter: LocalFilterModel): FilterModel {
    if (!filter) return null;
    const { filterList, logic } = filter;
    return {
      logic: logic,
      filters: filterList.map(({ field, operator, value }) => ({
        field,
        operator,
        value: !Array.isArray(value) ? value : value.length === 1 ? value[0] : null,
        ...(Array.isArray(value) &&
          value.length > 1 && {
            logic: operator === FilterOperator.NotEqual ? FilterLogic.AND : FilterLogic.OR,
            filters: value.map(value => ({ field, operator, value })),
          }),
      })),
    };
  }
}
