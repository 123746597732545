import { cipo } from 'cipo';

cipo.factory('currencyService', ['URI', '$http', '$q', 'Message', 'userService', 'Dictionaries',
function (URI, $http, $q, Message, userService, Dictionaries) {

    var currencyService = {};

    var handleError = function (e) { Message.dberror(e); }

    currencyService.getCurrency = function (id) {
        var p = $q.defer();
        Dictionaries.Currencies().then(function(currencies) {
            userService.system.currencies = currencies || [];
            var currencyId = id || userService.system.context?.contract?.currencyId || userService.system.context?.project?.currencyId || userService.system.context?.program?.currencyId || userService.system.userdata?.defaultCurrencyId;
            var currency = userService.system.currencies.find(c => c.currencyId == currencyId);
            p.resolve(currency);
        });
        return p.promise;
    };

    currencyService.setCurrency = function ($scope, currency) {
        $scope.currency = currency;
        if ($scope.currencyChange) {
            $scope.currencyChange(currency);
        }
    }

    currencyService.getAndSetCurrencyById = function ($scope, currencyId) {
        currencyService.getCurrency(currencyId).then(function (c) {
            currencyService.setCurrency($scope, c);
        });
    };

    currencyService.getCurrencyByContractIdOrDefault = function (contractId) {
        if (!userService.system.currencies) {
            return undefined;
        }
        var contract = userService.getContextContract(contractId);
        return userService.system.currencies.find(c => c.currencyId == (contract?.currencyId || userService.system.userdata.defaultCurrencyId));
    }

    currencyService.setCurrencyOnFormInit = function ($scope, data) {
        if (data?.currency) {
            currencyService.setCurrency($scope, data.currency);
        }
    };

    currencyService.setCurrencyOnFormChange = function ($scope, fieldId, newValue, data, fields) {
        if (fieldId && fields && newValue && fields[fieldId]?.systemFieldName == 'currency') {
            currencyService.getAndSetCurrencyById($scope, newValue * 1);
        }
        else if (fieldId && fields && newValue && fields[fieldId]?.systemFieldName == 'program_id') {
            $http[URI.SYSTEM.GET_CURRENCY_BY_PROGRAM.method](`${URI.SYSTEM.GET_CURRENCY_BY_PROGRAM}?entityInstanceId=${newValue}`.toString())
                .then(function (c) {
                    currencyService.setCurrency($scope, c.data);
                })
                .catch(handleError);
        }
        else if (fieldId && fields && newValue && fields[fieldId]?.systemFieldName == 'project_id') {
            $http[URI.SYSTEM.GET_CURRENCY_BY_PROJECT.method](`${URI.SYSTEM.GET_CURRENCY_BY_PROJECT}?entityInstanceId=${newValue}`.toString())
                .then(function (c) {
                    currencyService.setCurrency($scope, c.data);
                })
                .catch(handleError);
        }
    };

    return currencyService;
}]);
