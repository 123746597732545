<mat-drawer-container class="drawer-container">
    <mat-drawer [opened]="isMenuOpened()" mode="side" class="drawer-sidenav cipo-bg--primary-50">
        <app-menu
            #appmenu
            [menuItems]="menuItems"
            [activeMenuItemId]="activeMenuItemId"
            [menuFolderAutoCollapse]="menuFolderAutoCollapse"
            (onMenuItemClick)="menuItemClick($event)"></app-menu>
    </mat-drawer>

    <div class="drawer-sidenav-content">
        <app-header [menuOpened]="isMenuOpened()"
                    [context]="context"
                    [useOnlyContract]="useOnlyContract"
                    [userId]="userId"
                    [refresh]="refresh"
                    (onMenuClick)="toggleMenu()"
                    (onLogoClick)="logoClick()"
                    (contextChange)="contextChange.emit($event)"
                    (contextData)="contextData.emit($event)">
            <ng-content select="[header-content]"
                        header-content>
            </ng-content>
        </app-header>
        <div class="drawer-content">
            <ng-content></ng-content>
        </div>
        <app-footer></app-footer>
    </div>
</mat-drawer-container>
