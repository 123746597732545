import { Component, signal, inject } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';

import { FormItem } from '../../../module/definition/form/common/form-builder.interface';
import { MOCK_FORM_FIELDS } from '../../data/demo-form-builder.const';
import { FormBuilderService } from '../../../module/definition/form/common/form-builder.service';
import { FormBuilderModalComponent } from '../../../module/instance/form-builder-modal/form-builder-modal.component';
import { DIALOG_SIZE } from '../../../shared/consts';
import { CipoControl, CipoForm } from '../../../shared/components/fields/common';
import { GridFormComponent } from '../../../module/definition/form/grid-form/grid-form.component';

@Component({
  selector: 'app-demo-form-builder',
  templateUrl: './demo-form-builder.component.html',
  standalone: true,
  imports: [GridFormComponent, MatButtonToggleModule, FlexLayoutModule, MatButtonModule, FormBuilderModalComponent],
  providers: [FormBuilderService],
})
export class DemoFormBuilderComponent {
  dialog = inject(MatDialog);
  fb = inject(FormBuilder);

  items = signal<FormItem[]>([]);
  form: CipoForm = this.fb.group({});
  editMode = signal(true);
  constructor(private formBuilder: FormBuilderService) {
    this.formBuilder.mapFieldsToFormItems(MOCK_FORM_FIELDS).forEach(item => {
      const control = new CipoControl(item.value, item.fieldOptions, [
        ...(item.fieldOptions.required ? [Validators.required] : []),
      ]);
      this.form.addControl(item.id, control);
    });
  }

  logItems() {
    console.log(this.items());
  }

  openBuilder() {
    this.dialog.open(FormBuilderModalComponent, {
      panelClass: ['cipo-dialog', 'classic'],
      ...DIALOG_SIZE.xl,
    });
  }
}
