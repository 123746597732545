import { Component, ElementRef, input, signal, viewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CipoTextConfig, CipoTextControl } from '../common';
import { CipoFormModule } from '../../../modules/forms.module';

@Component({
  selector: 'cipo-text',
  standalone: true,
  imports: [CipoFormModule, TranslateModule],
  templateUrl: './cipo-text.component.html',
})
export class CipoTextComponent {
  fieldData = signal<CipoTextConfig>({});
  canClearInput = signal(true);

  chipInput = viewChild<ElementRef<HTMLInputElement>>('chipInput');
  selectedChipIndex: number;

  formControl = input<CipoTextControl, CipoTextControl>(null, {
    transform: control => {
      this.onFieldDataChange(control);
      return control;
    },
    alias: 'control',
  });

  onFieldDataChange(control: CipoTextControl) {
    const fieldData = control.fieldData ?? {};
    this.fieldData.set(fieldData);
    const { readonly, suffixIcon, preventClearInput } = fieldData;
    this.canClearInput.set(!readonly && !preventClearInput && !suffixIcon);
  }

  add({ value, chipInput }: MatChipInputEvent) {
    if (!value) {
      return;
    }
    const values = this.formControl().value as string[];
    if (this.selectedChipIndex !== undefined) {
      values.splice(this.selectedChipIndex, 1, value);
      this.selectedChipIndex = undefined;
    } else {
      values.push(value);
    }
    chipInput.clear();
    this.formControl().setValue(values);
  }

  edit(index: number) {
    this.selectedChipIndex = index;
    this.chipInput().nativeElement.value = this.formControl().value[index];
    const values = this.formControl().value as string[];
    this.formControl().setValue(values);
    this.chipInput().nativeElement.focus();
  }

  remove(index: number) {
    const values = this.formControl().value as string[];
    values.splice(index, 1);
    this.formControl().setValue(values);
  }
}
