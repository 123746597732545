import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { SharedComponentsModule } from '../../../../shared/modules/shared-components.module';
import { HolidayFormGroup, HolidayModel, HolidayRow, HolidaysService } from '../common';
import { CipoFieldsModule } from '../../../../shared/modules/cipo-fields.module';
import {
  CipoCheckboxControl,
  CipoDateTimeControl,
  CipoFieldTypes,
  CipoTextControl,
} from '../../../../shared/components/fields/common';
import { AppState } from '../../../../../app.state';

@Component({
  selector: 'app-add-edit-holiday',
  standalone: true,
  imports: [SharedComponentsModule, CipoFieldsModule],
  templateUrl: './add-edit-holiday.component.html',
})
export class AddEditHolidayComponent {
  dialogData = inject<HolidayRow>(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<AddEditHolidayComponent>);
  fb = inject(FormBuilder);
  translate = inject(TranslateService);
  holidaysService = inject(HolidaysService);
  store = inject<Store<AppState>>(Store);

  form: FormGroup<HolidayFormGroup>;
  constructor() {
    const name = new CipoTextControl(
      this.dialogData?.name ?? '',
      {
        type: CipoFieldTypes.Text,
        label: this.translate.instant('holidays.name'),
      },
      Validators.required,
    );

    const date = new CipoDateTimeControl(
      this.dialogData?.date ?? '',
      {
        type: CipoFieldTypes.DateTime,
        label: this.translate.instant('holidays.date'),
      },
      Validators.required,
    );

    const isWorkDay = new CipoCheckboxControl(this.dialogData?.isWorkDay ?? false, {
      type: CipoFieldTypes.Checkbox,
      label: this.translate.instant('holidays.isWorkDay'),
    });
    this.form = this.fb.group({
      name,
      date,
      isWorkDay,
    });
  }

  save() {
    const { date, isWorkDay, name } = this.form.value;
    const body: HolidayModel = {
      id: this.dialogData?.id ?? null,
      permissions: this.dialogData?.permissions ?? [],
      date: date as string,
      isWorkDay,
      name: name as string,
    };
    if (this.dialogData) {
      this.holidaysService.updateHoliday(body).subscribe(() => this.dialogRef.close(true));
    } else {
      this.holidaysService.createHoliday(body).subscribe(() => this.dialogRef.close(true));
    }
  }
}
