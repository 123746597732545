import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs';

import { HttpService } from '../../../../shared/services';
import { ListResponse } from '../../../../models/helpers';
import { HolidayModel } from './holidays.model';
import {
  DataListCommandFilterType,
  FiltersToApply,
  LocalFilterService,
} from '../../../../shared/components/data-list/common';

enum HolidaysFilter {
  NAME = 'name',
  DATE = 'date',
  IS_WORK_DAY = 'isWorkDay',
}

@Injectable({ providedIn: 'root' })
export class HolidaysService {
  http = inject(HttpService);
  translate = inject(TranslateService);
  localFilterService = inject(LocalFilterService);
  private holidays: HolidayModel[] = [];

  getAllHolidays() {
    return this.http.post<ListResponse<HolidayModel>>('_api/Holiday/Search', {}, { useLoader: false }).pipe(
      map(res => res.data),
      tap(holidays => (this.holidays = holidays)),
    );
  }

  createHoliday(body: HolidayModel) {
    return this.http.post<HolidayModel>(`_api/Holiday/Create`, body, { useLoader: false });
  }

  updateHoliday(body: HolidayModel) {
    return this.http.put<HolidayModel>('_api/Holiday/Update', body, { useLoader: false });
  }

  deleteHolidays(list: number[]) {
    return this.http.put('_api/Holiday/Delete', { list }, { useLoader: false });
  }

  getFilteredHolidays(filtersToApply: FiltersToApply) {
    let filteredHolidays = [...this.holidays];
    if (filtersToApply?.filters?.filterList) {
      filteredHolidays = this.localFilterService.getFilteredHolidays(filteredHolidays, filtersToApply);
    }
    const { criteria } = filtersToApply;
    return criteria
      ? filteredHolidays.filter(holiday => holiday.name.toLowerCase().includes(criteria.toLowerCase()))
      : filteredHolidays;
  }

  getFilters(): DataListCommandFilterType[] {
    return [
      {
        fieldId: HolidaysFilter.NAME,
        type: 'text',
        name: 'holidays.name',
      },
      {
        fieldId: HolidaysFilter.DATE,
        type: 'date',
        name: 'holidays.date',
      },
      {
        fieldId: HolidaysFilter.IS_WORK_DAY,
        type: 'text',
        name: 'holidays.isWorkDay',
        operators: ['Equal'],
        selectedOperator: 'Equal',
        values: [
          {
            key: false,
            value: 'general.no',
          },
          {
            key: true,
            value: 'general.yes',
          },
        ],
      },
    ];
  }
}
